import React, {Suspense} from "react";
import { AuthProvider } from "./contexts/AuthContext"
import { BrowserRouter, Route, Switch } from "react-router-dom";
import './App.css';
import ForgetPassword from './views/auth/forgetpassword/forgetpassword'
import PrivateRoute from "./components/PrivateRoute"


const Defaultlayout = React.lazy(() => import("./container/defaultlayout"));
const Login  = React.lazy(()=>import('./views/auth/login/login'))

const loading = () => (
  <div className="animated fadeIn pt-1 text-center">Loading...</div>
);

function App() {
  return (
  <BrowserRouter>
  <AuthProvider>
     <Suspense fallback={loading()}>
         <Switch>
         <PrivateRoute exact path="/" component={Defaultlayout} />
           <Route
           path="/login"
           name=""
           render={(props) => <Login {...props} />}>
           </Route>
           <Route
           path="/forgetPassword"
           name=""
           render={(props) => <ForgetPassword {...props} />}>
           </Route>
            <Route
              path="/"
              name=""
              render={(props) => <Defaultlayout {...props} />} />
        </Switch>
     </Suspense>
     </AuthProvider>
  </BrowserRouter>
  );
}

export default App;

import React, { useContext, useState, useEffect } from "react"
import { auth, db, storage } from "../firebase"
import firebase from "firebase/app"
const AuthContext = React.createContext()
const FieldValue = firebase.firestore.FieldValue;

export function useAuth() {
  return useContext(AuthContext)
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState()
  const [loading, setLoading] = useState(true)
  
  function signup(email, password) {
    return auth.createUserWithEmailAndPassword(email, password)
  }

  function login(email, password) {
    return auth.signInWithEmailAndPassword(email, password)
  }

  function logout() {
    return auth.signOut()
  }

  function addTopics(collection, id, docDictionary) {
    docDictionary.createdDate = firebase.firestore.Timestamp.fromDate(new Date())
    return db.collection(collection).doc(id).set(docDictionary)
  }

  function checkRecordExists(collection, id) {
    return db.collection(collection).doc(id).get()
  }

  function deleteKeyFromDocument(collection, id, key) {    
    var documentRef = db.collection(collection).doc(id);
    return documentRef.update({
      [key]: FieldValue.delete(),
    });
  }

  function deleteFromArrayByDocument(collection, id, key, value) {
    var documentRef = db.collection(collection).doc(id);
    return documentRef.update({
      [key]: FieldValue.arrayRemove(value)
    });
  }

  function addToArrayByDocument(collection, id, key, value) {
    var documentRef = db.collection(collection).doc(id);
    return documentRef.update({
      [key]: FieldValue.arrayUnion(value)
    });
  }

  function deleteUserPermanent(uid) {
    // Set the "capital" field of the city 'DC'
    db.collection("users").doc(uid).delete();
    db.collection("notificationSettings").doc(uid).delete();
    deleteCollection(db, "feeds", "authorID", uid)
    deleteCollection(db, "earningReward", "userId", uid)
    deleteCollection(db, "followRequest", "fromUserId", uid)
    deleteCollection(db, "followRequest", "toUserId", uid)
    deleteCollection(db, "linkRequest", "fromUserId", uid)
    deleteCollection(db, "linkRequest", "toUserId", uid)
    deleteCollection(db, "links", "fromUserId", uid)
    deleteCollection(db, "links", "toUserId", uid)
    deleteCollection(db, "notifications", "fromUid", uid)
    deleteCollection(db, "notifications", "toUid", uid)
    deleteCollection(db, "referReward", "referredUserId", uid)
    deleteCollection(db, "referReward", "referredFromUserId", uid)
  }

  async function deleteCollection(db, collectionPath, keyName, keyValue) {
    const collectionRef = db.collection(collectionPath);
    const query = collectionRef.where((keyName, '==', keyValue));
    return new Promise((resolve, reject) => {
      deleteQueryBatch(db, query, resolve).catch(reject);
    });
  }
  
  async function deleteQueryBatch(db, query, resolve) {
    const snapshot = await query.get();
  
    const batchSize = snapshot.size;
    if (batchSize === 0) {
      // When there are no documents left, we are done
      resolve();
      return;
    }
  
    // Delete documents in a batch
    const batch = db.batch();
    snapshot.docs.forEach((doc) => {
      batch.delete(doc.ref);
    });
    await batch.commit();
  
    // Recurse on the next process tick, to avoid
    // exploding the stack.
    process.nextTick(() => {
      deleteQueryBatch(db, query, resolve);
    });
  }

  function deleteFeed(feedId) {
    return db.collection('feeds').doc(feedId).update({enabled : false});
  }

  function resetPassword(email) {
    return auth.sendPasswordResetEmail(email)
  }

  function updateEmail(email) {
    return currentUser.updateEmail(email)
  }

  function updatePassword(password) {
    return currentUser.updatePassword(password)
  }

  function updateUserProfile(profile) {
    return currentUser.updateProfile(profile)
  }

  function collectionDetails(collection) {
    return db.collection(collection).get()
  }

  function updateCollectionDetails(collection, id, docDictionary) {
    return db.collection(collection).doc(id).update(docDictionary);
  }

  function getListOfNonEmptyArray(collection, key) {
    return db.collection(collection).where(key, '!=', []).get();
  }

  function getListOfWhereArray(collection, key, value) {
    return db.collection(collection).where(key, '==', value).get();
  }

  function updateListWithSearch(collection, key, value, docDictionary) {
    let promise = Promise.resolve();
    const collectionFiltered = db.collection(collection)
    collectionFiltered.where(key, '==', value)
    .get()
    .then(snapshots => {
      if (snapshots.size > 0) {
        snapshots.forEach(orderItem => {
          promise = promise.then(() => collectionFiltered.doc(orderItem.id).update(docDictionary))
          .then(() => console.log("Finished for"));
        })
        return promise
      }
    })
  };

  function getDateFromFirestore(date) {
    var year = date.getFullYear();
    var mes = date.getMonth()+1;
    var dia = date.getDate();
      return dia+"-"+mes+"-"+year
   }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setCurrentUser(user)
      setLoading(false)
    })

    return unsubscribe
  }, [])

  const value = {
    storage,
    currentUser,
    login,
    signup,
    logout,
    resetPassword,
    updateEmail,
    updatePassword,
    collectionDetails,
    deleteUserPermanent,
    addTopics,
    updateCollectionDetails,
    getDateFromFirestore,
    updateUserProfile,
    checkRecordExists,
    getListOfNonEmptyArray,
    updateListWithSearch,
    getListOfWhereArray,
    deleteKeyFromDocument,
    deleteFromArrayByDocument,
    addToArrayByDocument,
    deleteFeed,
  }

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  )
}
import React, { useRef, useState } from "react"
import { Form, Button,  Alert } from "react-bootstrap"
import { useAuth } from "../../../contexts/AuthContext"
import {  NavLink } from "react-router-dom"
import Logo from '../../../assets/Logo.png'

export default function ForgotPassword() {
  const emailRef = useRef()
  const { resetPassword } = useAuth()
  const [error, setError] = useState("")
  const [message, setMessage] = useState("")
  const [loading, setLoading] = useState(false)

  async function handleSubmit(e) {
    e.preventDefault()
    
    try {
      setMessage("")
      setError("")
      setLoading(true)
      await resetPassword(emailRef.current.value)
      setMessage("Check your inbox for further instructions")
    } catch {
      setError("Failed to reset password")
    }

    setLoading(false)
  }
  return ( 
    <div className="Login_container">
        <div className="login_inner-div">
            <div className="m-auto w-100">
            <img src={Logo} className="login-logo"  alt=""/>
            <p className="login_head">Forgot your password?</p>
            <p className="login_subhead mt-3">Enter details. We'll send you an recovery mail.</p>
            {error && <Alert variant="danger">{error}</Alert>}
          {message && <Alert variant="success">{message}</Alert>}
                <div className="login_form-div">
                    <Form onSubmit={handleSubmit}>
            <Form.Group id="email">
              <Form.Control type="email" ref={emailRef} className="form-custom-input" placeholder="Email Address"  required />
            </Form.Group>
                    <Button disabled={loading} className="modal_send-btn" type="submit">
              Reset Password
            </Button>
            </Form>
            <div className="login_form-div">

            <div className="d-flex justify-content-center">
                        <NavLink to={'/login'} style={{textDecoration:"none"}}><p className="login_subhead forget">Back to Login</p></NavLink>
            </div>
            </div>
                </div>
            </div>
        </div>
    </div>
 );
}

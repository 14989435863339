import firebase from "firebase/app"
import "firebase/firestore"
import "firebase/auth"
import 'firebase/storage';

const app = firebase.initializeApp({
  apiKey: "AIzaSyCIrj7fNUS4lkys9hWilL32CeKe3bxYruE",
  authDomain: "ocureel.firebaseapp.com",
  projectId: "ocureel",
  storageBucket: "ocureel.appspot.com",
  messagingSenderId: "305152595643",
  appId: "1:305152595643:web:f95a9803124da848febb9e",
  measurementId: "G-CCNPF964H0"
})

const db = firebase.firestore();
const storage = firebase.storage()
const auth = app.auth()
export  {
  storage, auth, db, app as default
}
